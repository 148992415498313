import React from 'react'
import { SkolkovoLogo, StyledSkolkovoLabel } from './Skolkovo.styles'

export const Skolkovo = () => (
  <StyledSkolkovoLabel>
    <a href="https://sk.ru/" rel="noreferrer" target="_blank">
      <SkolkovoLogo />
    </a>
  </StyledSkolkovoLabel>
)
