import React from 'react'
import SocialList from '../SocialList'
import styled from 'styled-components'
import { FooterSubscribeForm } from './FooterSubscribeForm'
import { FooterUnderLine } from '../../atoms/FooterUnderLine/FooterUnderLine'
import { Grid } from '../../atoms/Grid'
import { Link } from 'gatsby'
import { Skolkovo } from '../../atoms/Skolkovo/Skolkovo'
import { color } from '../../styles/vars/colors'
import { omit } from 'rambda'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { size } from '../../constants'
import { useSelector } from 'react-redux'

const navLinks = [
  {
    path: '/about/',
    name: 'О нас'
  },
  {
    path: '/tariff/',
    name: 'Тарифы'
  },
  {
    path: '/b2b/',
    name: 'Для бизнеса'
  },
  {
    path: '/be-in-team/',
    name: 'Психологам'
  },
  {
    path: '/faq/',
    name: 'FAQ'
  },
  {
    path: '/blog/',
    name: 'Блог'
  },
  {
    path: '/webinars/',
    name: 'Вебинары'
  },
  {
    path: '/podcast/',
    name: 'Подкаст'
  },
  {
    path: '/sertifikat-na-psihoterapiyu/',
    name: 'Сертификат'
  },
  {
    path: '/contacts/',
    name: 'Контакты'
  },
  {
    name: 'Каталог психологов',
    path: '/catalog/'
  },
  {
    name: 'Подбор психолога',
    path: '/wizard/'
  },
  {
    name: 'Подвешенные сессии',
    path: 'https://promo.youtalk.ru/podveshennye-konsultaciI/',
    type: 'externalLink'
  },
  {
    name: 'Карта сайта',
    path: '/sitemap/',
    type: 'externalLink'
  }
]

const navLinksCol1 = navLinks.slice(0, 5)
const navLinksCol2 = navLinks.slice(5, 10)
const navLinksCol3 = navLinks.slice(10, 14)

const navLinksCol1SM = navLinks.slice(0, 7)
const navLinksCol2SM = navLinks.slice(7, 14)

const NavLink = styled(({ className, children, ...props }) =>
  props.type === 'externalLink' ? (
    <a className={className} href={props.to} {...omit(['type', 'to'], props)}>
      {children}
    </a>
  ) : (
    <Link className={className} {...omit('type', props)}>
      {children}
    </Link>
  )
)`
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: ${color.text.white};

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
    color: ${color.text.white};
  }
`

const NavCol = styled(({ className, data }) => (
  <div className={className}>
    {data.map(({ name, path, type }) => (
      <NavLink key={path} to={path} type={type}>
        {name}
      </NavLink>
    ))}
  </div>
))`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-height: 184px;

  @media (max-width: ${size.md}) {
    max-height: 264px;
  }

  @media (max-width: ${size.sm}) {
    max-height: fit-content;
  }

  ${NavLink} {
    max-width: 204px;
  }
`

const NavLinksFlex = styled.div`
  display: grid;
  grid-template-columns: 204px 204px 193px;

  @media (max-width: ${size.lg}) {
    grid-template-columns: 164px 164px 193px;
  }

  @media (max-width: ${size.md}) {
    grid-template-columns: 111px 204px;
    column-gap: 75px;
  }

  @media (max-width: ${size.sm}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: unset;
  }

  @media (max-width: ${size.xs}) {
    display: flex;
    column-gap: 50px;
  }
`

const SocialsWithSkolkovo = styled(({ className }) => (
  <div className={className}>
    <Skolkovo />
    <SocialList />
    <p className="footer__subscrib-desc2">
      *Meta Platforms Inc. (соц. сети Facebook, Instagram) признана
      экстремистской, её деятельность запрещена на&nbsp;территории России
    </p>
  </div>
))`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding-left: 92px;

  ${SocialList} {
    padding-top: 28px;
  }

  @media (max-width: ${size.lg}) {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0;

    & p {
      display: none;
    }

    ${SocialList} {
      position: absolute;
      right: 23px;
      top: 0;
      padding-top: 0;
    }
  }

  @media (max-width: ${size.md}) {
    ${SocialList} {
      position: absolute;
      right: -8px;
    }
  }

  @media (max-width: ${size.sm}) {
    ${SocialList} {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: ${size.xs}) {
    gap: 24px;
    flex-direction: column;
    align-items: start;

    ${SocialList} {
      position: relative;

      & li {
        margin-bottom: 0 !important;
      }
    }
  }
`

const FooterGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 316px 1fr;
  gap: 11px;

  @media (max-width: ${size.lg}) {
    grid-template-columns: auto 345px;
    grid-template-rows: 184px auto;
    column-gap: 84px;
    row-gap: 24px;
  }

  @media (max-width: ${size.md}) {
    grid-template-columns: auto 292px;
    grid-template-rows: 264px auto;
    column-gap: 88px;
  }

  @media (max-width: ${size.sm}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    column-gap: 0;
  }
`

const FooterLine = styled.hr`
  display: block;
  width: 100%;
  height: 1px;
  margin: 24px 0 0;
  border: none;
  background: #9d9d9d;
  opacity: 0.3;
`

const Footer = styled(({ className }) => {
  const windowWidth = useSelector(selectDeviceSize)
  return (
    <footer className={className}>
      <Grid>
        <FooterGrid>
          <NavLinksFlex>
            {windowWidth < 1023 ? (
              <>
                <NavCol data={navLinksCol1SM} />
                <NavCol data={navLinksCol2SM} />
              </>
            ) : (
              <>
                <NavCol data={navLinksCol1} />
                <NavCol data={navLinksCol2} />
                <NavCol data={navLinksCol3} />
              </>
            )}
          </NavLinksFlex>
          <FooterSubscribeForm />
          <SocialsWithSkolkovo />
        </FooterGrid>
        <FooterLine />
        <FooterUnderLine />
      </Grid>
    </footer>
  )
})`
  max-width: calc(100vw - var(--scroll-width));
  position: relative;
  padding: 40px 0 0;
  background: ${color.backgroundBlue};

  @media (max-width: ${size.xs}) {
    padding-top: 32px;
  }
`

export default Footer
