import React from 'react'
import {
  Copyright,
  JuristicDocuments,
  PaymentsMethods
} from './FooterUnderlineItems'
import { StyledFooterUnderLine } from './FooterUnderLine.styles'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const screenSM = 767

export const FooterUnderLine = () => {
  const wWidth = useSelector(selectDeviceSize)

  return (
    <StyledFooterUnderLine>
      {wWidth > screenSM ? (
        <>
          <Copyright />
          <JuristicDocuments />
          <PaymentsMethods />
        </>
      ) : (
        <>
          <JuristicDocuments />
          <PaymentsMethods />
          <Copyright />
        </>
      )}
    </StyledFooterUnderLine>
  )
}
