import styled from 'styled-components'
import { size } from '../../constants'

export const StyledFooterUnderLine = styled.div`
  display: flex;
  padding: 16px 0;

  @media (max-width: ${size.xs}) {
    padding: 24px 0;
  }

  @media (max-width: ${size.sm}) {
    flex-wrap: wrap;
  }
`

export const StyledFooterUnderLineItem = styled.div`
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  opacity: 0.7;

  a {
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #fff;
    transition: color 0.2s ease;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: #fff;
    }
  }
`
