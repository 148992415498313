import styled, { css } from 'styled-components'
import { maxWidth, size } from '../constants'

export const Grid = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  margin: 0 auto;
  position: relative;
  max-width: ${maxWidth.lg};

  @media (max-width: ${size.lg}) {
    padding: 0 32px;
  }

  @media (max-width: ${size.md}) {
    padding: 0 32px;
  }
  @media (max-width: ${size.sm}) {
    padding: 0 20px;
  }
  @media (max-width: ${size.xs}) {
    padding: 0 16px;
  }
`

export const getGridColWidth = (columnsCount, maxColumns = 12) => css`
  --defaultGap: 24px;
  --colWidth: calc(
    (100% - (${maxColumns - 1} * var(--defaultGap))) / ${maxColumns}
  );
  width: calc(
    (var(--colWidth) * ${columnsCount}) +
      (${columnsCount - 1} * var(--defaultGap))
  );
`
