import skolkovo from './img/skolkovo.svg'
import skolkovoMobile from './img/skolkovo-mobile.svg'
import styled from 'styled-components'
import { size } from '../../constants'

export const StyledSkolkovoLabel = styled.div`
  display: flex;
  flex-wrap: wrap;

  img {
    margin-right: 16px;
  }
`

export const SkolkovoLogo = styled.div`
  width: 171px;
  height: 50px;
  background: url(${skolkovo}) no-repeat;

  @media (max-width: ${size.md}) {
    background: url(${skolkovoMobile}) no-repeat;
    width: 116px;
    height: 34px;
  }
`
